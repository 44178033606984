.image-modal-wrapper {
    max-height: 80vh;
    max-width: 80vw; /* Ensure the width can scroll horizontally */
    overflow: auto; /* Enable scrolling */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-controls {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    gap: 10px;
  }
  
  .image-controls button {
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 5px;
    cursor: pointer;
  }
  
  .image-controls button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .image-modal-img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    cursor: pointer;
    transition: transform 0.3s ease; /* Smooth transition for zoom */
  }
  