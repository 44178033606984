/* Footer Section */
#footer {
    background: #000066;
    color: #fff;
    font-size: 14px;
    padding: 15px 0;
}

/* Footer Top Section */
#footer .footer-top {
    background: #990066;
    padding: 30px 0 20px;
}

/* Footer Contact Section */
#footer .footer-top .footer-contact h3 {
    font-size: 24px;
    margin: 0 0 15px;
    padding: 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
    color: #106eea;
}

#footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Rubik", sans-serif;
    color: #777;
}

/* Footer Links Section */
#footer .footer-top h4 {
    font-size: 28px;
    font-weight: 600;
    color: #f1f6fe;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    text-align: center;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul li {
    padding: 6px 0;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.5s;
    display: inline-block;
    line-height: 25px;
    font-size: 20px;
    font-weight: 600;
}

#footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #000066;
}

/* Social Links Section */
#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #106eea;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #3b8af2;
    text-decoration: none;
}

/* Copyright Section */
#footer .copyright {
    text-align: center;
    font-size: 16px;
}

/* Border Bottom Section */
.bor_bottom_w {
    border-bottom: 2px solid #fff;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

/* Static Link Section */
.static_link {
    display: flex;
    justify-content: space-around;
    padding-top: 50px;
}

.static_link a {
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    transition: 0.8s;
    line-height: 20px;
}

.static_link a:hover {
    border-bottom: 1px solid #f5f5f5;
}

/* Store Icon Section */
.store_icon img {
    padding: 40px 6px 0;
}

/* Social Link Section */
.social_link a {
    color: #fff;
    padding-left: 18px;
    font-size: 33px;
    transition: 0.6s;
}

.social_link a:hover {
    color: #006;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
    #footer .copyright,
    #footer .credits {
        float: none;
        text-align: center;
        padding: 2px 0;
    }

    /* Footer Contact Section */
    #footer .footer-top .footer-contact h3 {
        font-size: 20px;
    }

    #footer .footer-top .footer-contact p {
        font-size: 12px;
    }

    /* Footer Links Section */
    #footer .footer-top h4 {
        font-size: 20px;
        padding-bottom: 10px;
    }

    #footer .footer-top .footer-links ul a {
        font-size: 14px;
    }

    /* Social Links Section */
    #footer .footer-top .social-links a {
        font-size: 18px;
        width: 30px;
        height: 30px;
    }

    /* Static Link Section */
    .static_link a {
        font-size: 14px;
    }

    /* Store Icon Section */
    .store_icon img {
        padding: 20px 6px 0;
    }

    /* Media Centre Section */
    .press {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        grid-gap: 10px;
    }

    /* Center align footer logo */
    #footer .footer-top .footer-logo {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    #footer .footer-top .footer-logo img {
        max-width: 100%;
        height: auto;
    }
}
.coming-soons-label {
    position: absolute;
    top: 140px;
    right: 25px;
    font-size: 12px;
    font-weight: bold;
    padding: 4px 8px;
    text-transform: capitalize;
    border-radius: 12px;
    z-index: 10;
    background: linear-gradient(90deg, #990066, #ffffff, #000066);
    /* New gradient */
    background-size: 200% 100%;
    -webkit-background-clip: text;
    color: transparent;
    animation: flowingGradient 3s linear infinite;
    white-space: nowrap;
    text-align: center;
    letter-spacing: 1px;
}

@keyframes flowingGradient {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 200% 0;
    }
}